import React from 'react'
import PropTypes from 'prop-types'

import { getEngineDisplayName } from 'app/views/utils/engine'

const ENGINE_INFO = {
  c: {
    versionStrings: [['Compiler Version', 'gcc 13.2']],
    libraries: [
      ['json-c', 'https://github.com/json-c/json-c'],
      ['libargon', 'https://github.com/P-H-C/phc-winner-argon2'],
      ['libjwt', 'https://github.com/benmcollins/libjwt'],
      ['mysqlclient', 'https://dev.mysql.com/doc/c-api/8.0/en/'],
      ['openssl', 'https://www.openssl.org/'],
      ['libxml2', 'https://gitlab.gnome.org/GNOME/libxml2'],
    ],
  },
  cpp: {
    versionStrings: [['Compiler Version', 'g++ 13.1.0']],
    libraries: [
      ['jwt-cpp 0.7.0', 'https://github.com/Thalhammer/jwt-cpp'],
      ['libargon2', 'https://github.com/P-H-C/phc-winner-argon2'],
      ['libjsoncpp', 'https://github.com/open-source-parsers/jsoncpp'],
      ['openssl 1.1.1', 'https://github.com/openssl/openssl'],
      ['mysqlclient', 'https://dev.mysql.com/downloads/c-api/'],
      [
        'mysql-connector/C++ 8.0.31',
        'https://dev.mysql.com/doc/connector-cpp/8.0/en/',
      ],
      ['ping', 'https://github.com/iputils/iputils/'],
      ['Xerces-C++', 'https://xerces.apache.org/xerces-c/'],
    ],
  },
  cobol: {
    versionStrings: [['gnuCOBOL', '3.1.2.0']],
    libraries: [['dbpre', 'https://sourceforge.net/projects/dbpre/']],
  },
  dotnet: {
    versionStrings: [['.NET Version', '8.0.13']],
    libraries: [
      [
        'Isopoh.Cryptography.Argon2 (1.1.12)',
        'https://github.com/mheyman/Isopoh.Cryptography.Argon2',
      ],
      ['MySql.Data (8.0.32.1)', 'https://www.nuget.org/packages/MySql.Data'],
      [
        'Newtonsoft.Json (13.0.3)',
        'https://github.com/JamesNK/Newtonsoft.Json',
      ],
      ['Portable.BouncyCastle (1.9.0)', 'https://www.bouncycastle.org/csharp/'],
    ],
  },
  clojure: {
    versionStrings: [['Clojure Version', '1.10.1']],
    libraries: [
      ['buddy/buddy-sign (3.3.0)', 'https://github.com/funcool/buddy-sign'],
      ['cheshire (5.10.0)', 'https://github.com/dakrone/cheshire'],
      ['cryptohash-clj (0.1.10)', 'https://github.com/jimpil/cryptohash-clj'],
      ['io.djy/ezzmq (0.4.0)', 'https://github.com/daveyarwood/ezzmq'],
      [
        'mysql/mysql-connector-java (8.0.23)',
        'http://svn.mysql.com/svnpublic/connector-j/trunk/connector-j',
      ],
      [
        'org.apache.commons/commons-text (1.9)',
        'https://commons.apache.org/proper/commons-text/',
      ],
      ['org.clojure/data.xml (0.0.8)', 'https://github.com/clojure/data.xml'],
      [
        'org.clojure/java.jdbc (0.7.12)',
        'https://github.com/clojure/java.jdbc',
      ],
      ['ring/ring-core (1.8.2)', 'https://github.com/ring-clojure/ring'],
    ],
  },
  go: {
    versionStrings: [['Go Version', '1.21']],
    libraries: [
      [
        'github.com/dgrijalva/jwt-go (3.2.2)',
        'https://github.com/dgrijalva/jwt-go',
      ],
      [
        'github.com/go-sql-driver/mysql (1.6.0)',
        'https://github.com/go-sql-driver/mysql',
      ],
      [
        'github.com/konsorten/go-windows-terminal-sequences (1.0.2)',
        'https://github.com/konsorten/go-windows-terminal-sequences',
      ],
      ['github.com/kr/pretty (0.1.0)', 'https://github.com/kr/pretty'],
      [
        'github.com/matthewhartstonge/argon2',
        'https://github.com/matthewhartstonge/argon2',
      ],
      ['github.com/pebbe/zmq4', 'https://github.com/pebbe/zmq4'],
      [
        'github.com/sirupsen/logrus (1.4.2)',
        'https://github.com/sirupsen/logrus',
      ],
      [
        'github.com/stretchr/testify (1.7.0)',
        'https://github.com/stretchr/testify',
      ],
      ['golang.org/x/net (0.7.0)', 'https://cs.opensource.google/go/x/net'],
      ['gopkg.in/check.v1 (1.0.0)', 'https://github.com/go-check/check'],
    ],
  },
  java: {
    versionStrings: [['JDK Version', 'Eclipse Temurin 17']],
    libraries: [
      ['com.google.guava:guava (23.0)', 'https://github.com/google/guava'],
      ['de.mkammerer:argon2-jvm (2.11)', 'https://github.com/phxql/argon2-jvm'],
      ['org.zeromq:jeromq (0.4.3)', 'https://github.com/zeromq/jeromq'],
      [
        'com.googlecode.json-simple:json-simple (1.1.1)',
        'https://code.google.com/archive/p/json-simple/',
      ],
      [
        'mysql:mysql-connector-java (5.1.45)',
        'https://mvnrepository.com/artifact/mysql/mysql-connector-java',
      ],
      ['io.jsonwebtoken:jjwt-api (0.10.7)', 'https://github.com/jwtk/jjwt'],
      ['io.jsonwebtoken:jjwt-impl (0.10.7)', 'https://github.com/jwtk/jjwt'],
      ['io.jsonwebtoken:jjwt-jackson (0.10.7)', 'https://github.com/jwtk/jjwt'],
      [
        'org.apache.commons:commons-text (1.9)',
        'https://commons.apache.org/proper/commons-text/',
      ],
      [
        'org.apache.commons:commons-lang (3:3.7)',
        'https://mvnrepository.com/artifact/org.apache.commons/commons-lang3',
      ],
      [
        'org.bouncycastle:bcprov-jdk18on (1.72)',
        'https://www.bouncycastle.org/java.html',
      ],
      [
        'org.mongodb:mongo-java-driver (2.12.3)',
        'https://mvnrepository.com/artifact/org.mongodb/mongo-java-driver',
      ],
      ['junit:junit (4.12)', 'https://mvnrepository.com/artifact/junit/junit'],
    ],
  },
  kotlin: {
    versionStrings: [['JDK Version', 'Eclipse Temurin 21']],
    libraries: [
      ['com.google.guava:guava (31.1-jre)', 'https://github.com/google/guava'],
      [
        'com.mysql:mysql-connector-j (8.0.32)',
        'https://dev.mysql.com/doc/connector-j/8.0/en/',
      ],
      ['de.mkammerer:argon2-jvm (2.11)', 'https://github.com/phxql/argon2-jvm'],
      ['io.jsonwebtoken:jjwt-api (0.11.5)', 'https://github.com/jwtk/jjwt'],
      ['io.jsonwebtoken:jjwt-impl (0.11.5)', 'https://github.com/jwtk/jjwt'],
      ['io.jsonwebtoken:jjwt-jackson (0.11.5)', 'https://github.com/jwtk/jjwt'],
      [
        'org.apache.commons:commons-text (1.10.0)',
        'https://commons.apache.org/proper/commons-text/',
      ],
      [
        'org.apache.commons:commons-lang3 (3.12.0)',
        'https://mvnrepository.com/artifact/org.apache.commons/commons-lang3',
      ],
      [
        'org.bouncycastle:bcprov-jdk18on (1.73)',
        'https://www.bouncycastle.org/java.html',
      ],
      ['org.json:json (20230227)', 'https://github.com/stleary/JSON-java'],
      [
        'org.mongodb:mongo-java-driver (3.12.13)',
        'https://mvnrepository.com/artifact/org.mongodb/mongo-java-driver',
      ],
    ],
  },
  node: {
    versionStrings: [['Node Version', '20.9']],
    libraries: [
      ['@noble/ed25519 (^1.7.3)', 'https://github.com/paulmillr/noble-ed25519'],
      ['argon2', '^0.31.1'],
      [
        'bigint-mod-arith (^3.2.1)',
        'https://github.com/juanelas/bigint-mod-arith',
      ],
      ['escape-html (^1.0.3)', 'https://github.com/component/escape-html'],
      ['jsdom (^19.0.0)', 'https://github.com/jsdom/jsdom'],
      ['jwt-decode (3.1.2)', 'https://github.com/auth0/jwt-decode'],
      ['libxmljs (1.0.9)', 'https://github.com/libxmljs/libxmljs'],
      ['mongodb (^5.6.0)', 'https://github.com/mongodb/node-mongodb-native'],
      ['mysql2 (^3.2.3)', 'https://github.com/mysqljs/mysql'],
      ['qs (6.11.0)', 'https://github.com/ljharb/qs'],
      [
        'sync-request (^6.1.0)',
        'https://github.com/ForbesLindesay/sync-request',
      ],
    ],
  },
  perl: {
    versionStrings: [['Perl Version', '5.38']],
    libraries: [
      ['CGI', 'https://metacpan.org/pod/CGI'],
      ['Crypt::Argon2', 'https://metacpan.org/pod/Crypt::Argon2'],
      ['Crypt::Ed25519', 'https://metacpan.org/pod/Crypt::Ed25519'],
      ['Crypt::Random', 'https://metacpan.org/pod/Crypt::Random'],
      ['DBI', 'https://metacpan.org/pod/DBI'],
      ['DBD::mysql', 'https://metacpan.org/pod/DBD::mysql'],
      ['Exception::Class', 'https://metacpan.org/pod/Exception::Class'],
      ['JSON::WebToken', 'https://metacpan.org/pod/JSON::WebToken'],
      [
        'Try::Tiny::SmartCatch',
        'https://metacpan.org/pod/Try::Tiny::SmartCatch',
      ],
      ['Math::BigInt', 'https://metacpan.org/dist/Math-BigInt'],
      ['XML::LibXML', 'https://metacpan.org/pod/XML::LibXML'],
      ['ZMQ::LibZMQ3', 'https://metacpan.org/pod/ZMQ::LibZMQ3'],
    ],
  },
  php: {
    versionStrings: [['PHP Version', '7.3']],
    libraries: [
      ['firebase/php-jwt (5.0.0)', null],
      ['react/zmq (0.4.0)', null],
      ['php7.3', null],
      ['php-bcmath', null],
      ['php-curl', null],
      ['php-mongodb', null],
      ['php-mysql', null],
      ['php-xml', null],
      ['php-zmq', null],
      ['libzmq3-dev', null],
    ],
  },
  python: {
    versionStrings: [['Interpreter Version', 'Python 3.12']],
    libraries: [
      ['argon2-cffi (21.3.0)', 'https://github.com/hynek/argon2-cffi'],
      ['cryptography (3.2)', 'https://github.com/pyca/cryptography/'],
      ['pycryptodome (3.15.0)', 'https://github.com/Legrandin/pycryptodome/'],
      ['PyJWT (2.8.0)', 'https://github.com/jpadilla/pyjwt'],
      ['PyMySQL (1.0.2)', 'https://github.com/PyMySQL/PyMySQL'],
      ['PyNaCl (1.5.0)', 'https://github.com/pyca/pynacl/'],
      ['requests (2.31.0)', 'https://requests.readthedocs.io/en/latest/'],
      ['untangle (1.1.1)', 'https://github.com/stchris/untangle'],
    ],
  },
  ruby: {
    versionStrings: [['Interpreter Version', 'Ruby 2.5.1']],
    libraries: [
      ['activesupport (7.0.4.3)', 'https://rubygems.org/gems/activesupport'],
      ['argon2 (2.2.0)', 'https://github.com/technion/ruby-argon2'],
      ['ed25519 (1.3.0)', 'https://github.com/RubyCrypto/ed25519'],
      ['faraday (1.4.1)', 'https://github.com/lostisland/faraday'],
      ['ffi-rzmq (2.0.7)', 'https://github.com/chuckremes/ffi-rzmq'],
      ['jwt (2.2.1)', 'https://github.com/jwt/ruby-jwt'],
      ['ruby-mysql (2.9.14)', 'https://github.com/tmtm/ruby-mysql'],
      ['mongo (2.18.2)', 'https://github.com/mongodb/mongo-ruby-driver'],
      ['mysql2 (0.5.3)', 'https://github.com/brianmario/mysql2'],
      ['nokogiri (1.11.3)', 'https://github.com/sparklemotion/nokogiri'],
      ['xml-simple (1.1.5)', 'https://github.com/maik/xml-simple'],
    ],
  },
  rust: {
    versionStrings: [['Rust Version', '1.73']],
    libraries: [
      ['aes-gcm (0.10.1)', 'https://docs.rs/aes-gcm/latest/aes_gcm/'],
      [
        'ed25519-dalek (1.0.1)',
        'https://github.com/dalek-cryptography/ed25519-dalek',
      ],
      ['flate2 (1.0.24)', 'https://docs.rs/flate2/latest/flate2/'],
      ['hex (0.4.3)', 'https://docs.rs/hex/latest/hex/'],
      ['hmac (0.12.1)', 'https://docs.rs/hmac/latest/hmac/'],
      ['jwt (0.16.0)', 'https://docs.rs/jwt/latest/jwt/'],
      ['mysql (23.0.1)', 'https://docs.rs/mysql/latest/mysql/'],
      ['rust-argon2 (1.0)', 'https://docs.rs/rust-argon2/latest/argon2/'],
      ['sha2 (0.10.5)', 'https://docs.rs/sha2/latest/sha2/'],
      ['sha3 (0.10.6)', 'https://docs.rs/sha3/latest/sha3/'],
      ['serde (1.0.104)', 'https://serde.rs/'],
      ['serde_json (1.0.48)', 'https://docs.rs/serde_json/latest/serde_json/'],
      ['thiserror (1.0.32)', 'https://docs.rs/thiserror/latest/thiserror/'],
      ['url (2.3.1)', 'https://docs.rs/url/latest/url/'],
    ],
  },
  scala: {
    versionStrings: [['JDK Version', 'OpenJDK 17.0.5']],
    libraries: [
      ['com.google.guava:guava (31.1-jre)', 'https://github.com/google/guava'],
      [
        'com.googlecode.json-simple:json-simple (1.1.1)',
        'https://code.google.com/archive/p/json-simple/',
      ],
      ['de.mkammerer:argon2-jvm (2.11)', 'https://github.com/phxql/argon2-jvm'],
      ['junit:junit (4.12)', 'https://mvnrepository.com/artifact/junit/junit'],
      [
        'mysql:mysql-connector-java (5.1.45)',
        'https://mvnrepository.com/artifact/mysql/mysql-connector-java',
      ],
      ['io.jsonwebtoken:jjwt-api (0.10.7)', 'https://github.com/jwtk/jjwt'],
      ['io.jsonwebtoken:jjwt-impl (0.10.7)', 'https://github.com/jwtk/jjwt'],
      ['io.jsonwebtoken:jjwt-jackson (0.10.7)', 'https://github.com/jwtk/jjwt'],
      [
        'org.apache.commons:commons-text (1.9)',
        'https://commons.apache.org/proper/commons-text/',
      ],
      [
        'org.mongodb:mongo-java-driver (2.12.3)',
        'https://mvnrepository.com/artifact/org.mongodb/mongo-java-driver',
      ],
      [
        'org.scala-lang:scala-library (2.13.6)',
        'https://mvnrepository.com/artifact/org.scala-lang/scala-library',
      ],
    ],
  },
  typescript: {
    versionStrings: [
      ['Node Version', '20.9'],
      ['TypeScript Version', '5.6.3'],
    ],
    libraries: [
      ['@noble/ed25519 (^1.7.3)', 'https://github.com/paulmillr/noble-ed25519'],
      ['argon2', '^0.31.1'],
      [
        'bigint-mod-arith (^3.2.1)',
        'https://github.com/juanelas/bigint-mod-arith',
      ],
      ['escape-html (^1.0.3)', 'https://github.com/component/escape-html'],
      ['jsdom (^19.0.0)', 'https://github.com/jsdom/jsdom'],
      ['jwt-decode (3.1.2)', 'https://github.com/auth0/jwt-decode'],
      ['libxmljs (1.0.9)', 'https://github.com/libxmljs/libxmljs'],
      ['mongodb (^5.6.0)', 'https://github.com/mongodb/node-mongodb-native'],
      ['mysql2 (^3.2.3)', 'https://github.com/mysqljs/mysql'],
      ['qs (6.11.0)', 'https://github.com/ljharb/qs'],
      [
        'sync-request (^6.1.0)',
        'https://github.com/ForbesLindesay/sync-request',
      ],
    ],
  },
}

const Item = ({ children }) => <p style={{ margin: '6px 10px' }}>{children}</p>
const LibraryListColumn = ({ children }) => (
  <div style={{ flexBasis: 0, flexShrink: 0, flexGrow: 1 }}>{children}</div>
)
const LibraryListItem = ({ text, url }) => (
  <div>
    &bull;{' '}
    {url && url.length > 0 ? (
      <a href={url} rel='noreferrer' target='_blank'>
        {text}
      </a>
    ) : (
      text
    )}
  </div>
)
const LibraryList = ({ namesAndUrls }) => {
  const splitAtIndex =
    namesAndUrls.length > 3 ? Math.round(namesAndUrls.length / 2) : -1
  const columns =
    splitAtIndex === -1
      ? [namesAndUrls]
      : [namesAndUrls.slice(0, splitAtIndex), namesAndUrls.slice(splitAtIndex)]
  return (
    <div style={{ marginLeft: 20, marginTop: 0, display: 'flex' }}>
      {columns.map((columnNamesAndUrls, i) => (
        <LibraryListColumn key={i}>
          {columnNamesAndUrls.map(([name, url], j) => (
            <LibraryListItem key={j} text={name} url={url} />
          ))}
        </LibraryListColumn>
      ))}
    </div>
  )
}

function LanguageEnvironmentDetails(props) {
  const { engine } = props
  const versionStrings = (ENGINE_INFO[engine] || {}).versionStrings || []
  const libraryNamesAndUrls = (ENGINE_INFO[engine] || {}).libraries || []
  return (
    <div style={{ marginTop: 20 }}>
      <h4 style={{ marginBottom: 0 }}>
        {getEngineDisplayName(engine)} Environment
      </h4>
      <Item>
        <b>Operating System:</b> Debian 12 (bookworm)
      </Item>
      {versionStrings.map(([description, value], index) => (
        <Item key={index}>
          <b>{description}:</b> {value}
        </Item>
      ))}
      {libraryNamesAndUrls.length > 0 && (
        <>
          <Item>
            <b>Third-party Libraries:</b>
          </Item>
          <LibraryList namesAndUrls={libraryNamesAndUrls} />
        </>
      )}
    </div>
  )
}
LanguageEnvironmentDetails.propTypes = {
  engine: PropTypes.string,
}

export default LanguageEnvironmentDetails
